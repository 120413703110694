<template>
    <div>
        <quill-editor
            ref="myTextEditor"
            v-model="productDetails"
            :options="editorOption"
            style="height: 300px"
            @change="onEditorChange($event)"
        ></quill-editor>
    </div>
</template>
<script>
import { quillEditor } from 'vue-quill-editor';
import editorOption from './editorOption';
export default {
    data() {
        return {
            productDetails: '',
            editorOption,
        };
    },
    components: {
        quillEditor,
    },

    methods: {
        onEditorChange() {},
    },
};
</script>
